<template>
	<div class="container">
		<img id="astronaut7" class="astronaut7" src="~@/assets/images/index/bg-page-7.png" />
	</div>
</template>

<script>
	export default {
		name: 'test5',
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				pixelRatio: window.pixelRatio,
				
			}
		},
		mounted() {
		},
		destroyed() {
			
		},
		methods: {}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		height: 100%;
		
		overflow: hidden;
	}
	
	.astronaut7 {
		position: absolute;
		left: 0%;
		top: 0%;
	
		width: 100%;
		height: 100%;
		object-fit: cover;
	
		/* transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg) scale(1.2); */
	}
</style>
